var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.libraryId !== 'community')?_c('app-kebab-actions',_vm._g(_vm._b({attrs:{"value":_vm.value},on:{"showMenu":_vm.onShowMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"pointer"},on),[_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,86454017)},'app-kebab-actions',_vm.$attrs,false),_vm.$listeners),[_c('p',{on:{"click":function($event){return _vm.showInfo()}}},[_c('v-icon',{attrs:{"size":"15","color":"lightBlack"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Info ")],1),_c('LibraryShareDialog',{attrs:{"invite-to-text-type":_vm.LIBRARY,"item":_vm.item || {
    },"members":_vm.itemMembers,"text":"Share","view-in-actions":""},on:{"manageSharedUser":function($event){return _vm.manageSharedUser({
      variables: Object.assign({}, $event.variables,
        {libraryId: _vm.libraryId}),
      criteria: $event.criteria,
    })}}}),_c('library-delete',{attrs:{"item":_vm.item || {
    }}},[_vm._v(" Delete ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }