<template>
  <div class="libraries">
    <LibraryListingSectionSkeleton v-if="isFetchingLibraries" />
    <div
      v-else-if="updatedCustomLibraries.length"
      class="libraries__content">
      <slot name="header" />
      <template>
        <div class="secondary-blocks">
          <ListingItem
            :libraries-list="updatedCustomLibraries"
            @navigate="navigate">
            <template #actions="{ item }">
              <v-icon
                medium
                color="mainGrey"
                class="brightness-icon"
                @click.stop="$emit('click-action-menu', {
                  item,
                  event: $event,
                })"
                v-on="$listeners">
                mdi-dots-vertical
              </v-icon>
            </template>
          </ListingItem>
        </div>
      </template>
    </div>
    <AppEmptyList
      v-else
      from="libraries"
      class="mt-12" />
  </div>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import ListingItem from '../ListingImageItem';
import LibraryListingSectionSkeleton from '@/components/App/AppSkeleton/Libraries/LibraryListingSectionSkeleton';
export default {
  name: 'Libraries',
  components: {
    ListingItem,
    LibraryListingSectionSkeleton,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  data() {
    return {
      size: 'main',
      updatedCustomLibraries: [],
    };
  },
  computed: {
    ...mapState('Libraries', ['isFetchingLibraries']),
    ...mapGetters('FeatureFlags', ['createAnchorForCollections']),
    ...mapGetters('Libraries', ['getCustomLibraries']),
    ...mapGetters(['userId']),
  },
  async created() {
    await this.getLibrariesList();
    await this.addRoute();
    // watch for further changes
    this.$watch('getCustomLibraries', async () => {
      await this.addRoute();
    });
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      getLibrariesList: 'Libraries/getLibrariesList',
    }),
    async addRoute() {
      if (!this.createAnchorForCollections) {
        this.updatedCustomLibraries = this.getCustomLibraries;
        return;
      }
      const updatedLib = this.getCustomLibraries.map((item) => {
        const { id } = item;
        const route = this.$router.resolve({
          name: id === 'community' ? 'community-collections' : 'collections',
          params: {
            id,
          },
        }).href;
        return {
          ...item,
          route,
        };
      });
      this.updatedCustomLibraries = updatedLib;
    },
    navigate(item) {
      const { id } = item;
      this.$router.push({
        name: id === 'community' ? 'community-collections' : 'collections',
        params: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" src="../listing.scss">
</style>
