import {
  mapMutations, mapGetters,
} from 'vuex';
export default {
  data() {
    return {
      syncTime: null,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useSkeleton']),
  },
  methods: {
    ...mapMutations(['setUploadingSpinner']),
    async goTo({ link }) {
      if (this.useSkeleton) {
        await this.$router.push(link).catch(() => {
        });
        return;
      }
      this.useSpinnerGoToLink(link);
    },
    useSpinnerGoToLink(link) {
      clearTimeout(this.syncTime);
      this.syncTime = setTimeout(() => {
        this.$router.push(link).catch(() => {});
      }, 100);
    },
  },
  beforeDestroy() {
    clearTimeout(this.syncTime);
  },
};
