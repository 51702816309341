<template>
  <component
    :is="isDialog ? 'v-dialog' : 'div'"
    v-if="fileForPreview || isDrag"
    :value="true">
    <div
      :style="{position: absolute ? 'absolute' : 'fixed'}"
      class="wrapper">
      <div
        :style="{maxWidth}"
        class="wrapper__container">
        <v-col
          :style="{height}"
          class="drag-drop-container">
          <v-icon
            class="closed-icon"
            @click="fileForPreview = null">
            mdi-close
          </v-icon>
          <v-row
            no-gutters
            class="flex-column justify-center align-center"
            style="height: 100%">
            <div class="w-100">
              <v-row
                class="mx-0 flex-column"
                justify="center"
                align="center">
                <div class="text-center">
                  <img
                    v-if="!file"
                    src="@/assets/icons/upload-icon.svg">
                  <div
                    v-else-if="fileForPreview && fileForPreview.includes('image') && isAvatar">
                    <Cropper
                      :is-not-crop="isNotCrop"
                      :img="fileForPreview"
                      :aspect-ratio="aspectRatio"
                      :upload-logo-file="uploadLogoFile"
                      @croppImage="croppImage" />
                  </div>
                  <img
                    v-else-if="fileForPreview && !fileForPreview.includes('image') && isAvatar"
                    :src="fileForPreview"
                    width="40"
                    height="40">
                  <img
                    v-else
                    src="@/assets/icons/file-icon.svg">
                </div>
                <p
                  v-if="file && fileForPreview && !isAvatar"
                  class="x-small-p mb-0 mt-1">
                  {{ file.name }}
                </p>
              </v-row>
              <div
                v-if="switcherCrop"
                class="d-flex justify-center mt-2">
                <v-switch
                  v-model="isCrop"
                  hide-details
                  :label="switcherBottomText" />
              </div>
              <p
                v-if="!fileForPreview"
                class="title-in-modal mt-4 d-flex justify-center">
                Drag and drop files here
              </p>
              <v-row
                v-else
                no-gutters
                class="mt-4"
                justify="center">
                <v-btn
                  color="lightBlue"
                  :loading="uploading"
                  @click="debouncedupload">
                  UPLOAD
                </v-btn>
              </v-row>
            </div>
          </v-row>
        </v-col>
      </div>
    </div>
  </component>
</template>
<script>
import { Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import {
  mapActions, mapState,
} from 'vuex';
import Cropper from '@/components/Account/AccountCropper';
import { cacheControl } from '@/constants/cores';
import { VDialog } from 'vuetify/lib';
import { debounce } from 'lodash';
import { DEBOUNCE_TIME_FOR_CLICK } from '@/constants';
export default {
  name: 'AppDragAndDropArea',
  components: {
    VDialog,
    Cropper,
  },
  props: {
    switcherBottomText: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '400px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    isDrag: {
      type: Boolean,
      default: false,
    },
    switcherCrop: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    rowId: {
      type: String,
      default: null,
    },
    tableId: {
      type: String,
      default: null,
    },
    file: {
      type: File,
      default: null,
    },
    isAvatar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: true,
    },
    uploadLogoFile: {
      type: Boolean,
      default: false,
    },
    customMetadata: {
      type: Object,
      default: null,
    },
    uploadLater: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCrop: false,
    fileForPreview: null,
    uploading: false,
    croppedFile: null,
  }),
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    isNotCrop() {
      return this.switcherCrop && !this.isCrop;
    },
  },
  watch: {
    file(val) {
      this.uploading = false;
      if (val) {
        const reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onload = (ev) => {
          this.fileForPreview = ev.target.result;
        };
      }
    },
  },
  methods: {
    ...mapActions(['handleError']),
    croppImage(file) {
      this.croppedFile = file;
    },
    debouncedupload: debounce(function () {
      this.upload();
    }, DEBOUNCE_TIME_FOR_CLICK),
    async upload() {
      try {
        this.uploading = true;
        if (this.uploadLogoFile) {
          this.$emit('uploadFile', this.isNotCrop ? this.file : this.croppedFile);
          return;
        }
        const file = this.isAvatar ? this.croppedFile : this.file;
        const id = this.isAvatar ? uuid() : `${uuid()}/${file.name}`;
        const commentId = uuid();
        const { tableId, rowId, type } = this;
        const baseMetadata = {
          workspace_id: this.activeWorkspaceId,
        };
        let metadata = baseMetadata;
        if (!this.isAvatar && !this.customMetadata) {
          metadata = {
            projectId: this.$route.params.id,
            tableId,
            tableType: 'schedule',
            ...!type && {
              rowId,
            },
            filename: file.name,
            commentId,
            ...baseMetadata,
          };
        }
        if (this.customMetadata) {
          metadata = {
            ...this.customMetadata,
            ...baseMetadata,
          };
        }
        const customPrefix = this.isAvatar ? {
          public: 'public/avatars/',
        } : {
        };
        const config = {
          customPrefix,
          contentType: file.type,
          level: 'public',
          metadata,
          cacheControl,
        };
        if (!this.uploadLater) {
          const { key } = await Storage.put(id, file, config);
          this.$emit('uploadFile', {
            documentId: key,
            commentId,
          });
          return;
        }
        this.$emit('uploadFile', {
          id,
          file,
          config,
        });
      } catch (err) {
        this.handleError(err);
      }
      finally {
        this.fileForPreview = null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper{
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &__container{
    height: 100%;
    display: flex;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
  }
}
.drag-drop-container {
  padding: 60px 0 30px 0;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
</style>
