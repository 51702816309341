<template>
  <div class="px-0 d-flex align-center">
    <app-dialog
      v-model="dialogForShare"
      :value.sync="dialogForShare"
      width="637"
      persistent
      content-class="v-dialog__form"
      @keydown.esc="dialogForShare = false ">
      <template #activator="{ on: onDialog }">
        <template v-if="dialogType === TYPE_COLLECTION_SHARE">
          <app-icon-btn
            wrapper-class="d-flex"
            bottom
            :disabled="disabled"
            show-tooltip
            class="collection-action-button"
            v-on="!disabled && onDialog">
            <template
              v-if="!viewInActions"
              #img>
              <img
                class="share-icon"
                src="@/assets/icons/share-action-icon.svg"
                alt="share">
            </template>
            <template
              v-else
              #img>
              <p
                class="small-p"
                style="width:100%">
                <img
                  class="v-icon__dialog-icon"
                  src="@/assets/icons/share-action-icon.svg"
                  alt="share">
                Share
              </p>
            </template>
          </app-icon-btn>
        </template>
        <template v-else>
          <v-btn
            color="lightBlue"
            v-on="onDialog">
            Invite members
          </v-btn>
        </template>
      </template>
      <v-card>
        <header-share-modal
          :dialog-type="dialogType"
          @closeModal="closeModal" />
        <share-content
          :invite-to-text-type="inviteToTextType"
          :show-select-errors="showSelectErrors"
          :permission-types="permissionTypes"
          :dialog-type="dialogType"
          :ws-guests="wsGuests"
          :members="members"
          :item="item"
          :disabled="disabled"
          :status-dialog="dialogForShare"
          v-on="$listeners"
          @closeModal="closeModal" />
      </v-card>
    </app-dialog>
  </div>
</template>
<script>
import { TYPE_COLLECTION_SHARE } from '@/constants';
import { GET_PERMISSION_TYPES } from '@/constants/userPermissions';
import ShareContent from '@/components/CollectionsLibrarysRelocate/ShareContent';
import HeaderShareModal from '@/components/CollectionsLibrarysRelocate/headerShare';
export default {
  name: 'CollectionShareDialog',
  components: {
    HeaderShareModal,
    ShareContent,
  },
  props: {
    wsGuests: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
    viewInActions: {
      type: Boolean,
      default: false,
    },
    showSelectErrors: {
      type: Boolean,
      default: false,
    },
    permissionTypes: {
      type: Array,
      default: () => GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
    inviteToTextType: {
      type: String,
      default: undefined,
    },
    item: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogForShare: false,
      TYPE_COLLECTION_SHARE,
    };
  },
  methods: {
    closeModal() {
      this.dialogForShare = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-dialog__form {
  overflow: hidden;
}
.small-p {
  padding: 0 !important;
}
</style>
