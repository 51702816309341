<template>
  <div
    v-if="displaySwitcher"
    class="d-flex align-center mb-0 listing">
    <div class="listing-container medium-p mb-3">
      <v-switch
        :input-value="inputValue"
        inset
        :data-test="!inputValue ? 'toggle_listing_view' : 'toggle_gallery_view'"
        hide-details
        :label="!inputValue ? 'Listing view' : 'Gallery view'"
        @change="toggleView" />
    </div>
  </div>
</template>
<script>
import LoadingSpinnerTimeout from '@/mixins/LoadingSpinnerTimeout';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import ToggleView from '@/mixins/ToggleViewMode';
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import {
  ROUTE_WORKSPACE_PAGE, ROUTE_WORKSPACE_PAGE_EDIT,
} from '@/constants';
export default {
  name: 'AppSwitcherToListing',
  mixins: [LoadingSpinnerTimeout, ToggleViewCondition, ToggleView],
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useSkeleton']),
    ...mapState(['isChangingView']),
    listingSwitcherInHeader() {
      return [
        'shared-workspace',
        'shared-collection',
        'libraries',
        'community-collections',
        'collections',
        'collection-library',
        'my-page',
        'verified-community',
        'collection-product-link',
        ROUTE_WORKSPACE_PAGE_EDIT,
        ROUTE_WORKSPACE_PAGE,
        'community-collection',
        'schedule-library',
      ].includes(this.$route.name);
    },
    inputValue() {
      return (this.value === null)
        ? this.toggleViewCondition('librariesRelatedPages')
        : this.value;
    },
    displaySwitcher() {
      return this.listingSwitcherInHeader && !this.$route.query.keyword && this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    ...mapMutations(['setIsLoadingRowData', 'setIsChangingView']),
    async toggleView(val) {
      clearTimeout(this.syncTime);
      if (this.useSkeleton) {
        this.setIsLoadingRowData(true);
        this.setIsChangingView(true);
        // if no value is passed to the component then run the deault functionality
        if (this.value === null) {
          this.toggleViewMode({
            value: val,
            criteria: 'librariesRelatedPages',
          });
        } else {
          this.$emit('toggleViewMode', val);
        }
      } else {
        this.setUploadingSpinner(true);
        this.syncTime = setTimeout(() => {
          // if no value is passed to the component then run the deault functionality
          if (this.value === null) {
            this.toggleViewMode({
              value: val,
              criteria: 'librariesRelatedPages',
            });
          } else {
            this.$emit('toggleViewMode', val);
          }
        }, 10);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .listing {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
  .listing-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
</style>
