<template>
  <div
    v-if="!isTopLevel"
    class="app-breadcrumb d-flex">
    <v-icon
      dense
      title="return back"
      class="cursor pointer"
      @click="goTo({ link: pushBackUrl })">
      mdi-arrow-left
    </v-icon>
    <v-breadcrumbs
      class="pl-3"
      :items="breadcrumbsList">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          tag="a"
          :disabled="item.disabled"
          @click.prevent="goTo({ link: item.to })">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>
<script>
import LoadingSpinnerTimeout from '@/mixins/LoadingSpinnerTimeout';
export default {
  name: 'AppBreadcrumbs',
  mixins: [LoadingSpinnerTimeout],
  props: {
    pushBackUrl: {
      type: String,
      default: '/',
    },
    breadcrumbsList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isTopLevel() {
      const path = [
        'projects',
        'collections',
        'my-page',
        'community-collections',
      ].includes(this.$route.name);
      const length = (this.breadcrumbsList.length == 2);
      return path && length;
    },
  },
};
</script>
<style scoped>
</style>
