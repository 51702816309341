<template>
  <div class="listing-item-skeleton">
    <v-skeleton-loader
      class="listing-item-skeleton__image"
      type="image" />
    <v-skeleton-loader
      class="listing-item-skeleton__text"
      type="text" />
  </div>
</template>

<script>
export default {
  name: 'CollectionListingItemSkeleton',
};
</script>

<style scoped lang="scss">
  @use 'src/components/Listing/listing';
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
    .v-skeleton-loader__text {
      height: 100%;
    }
  }
  .listing-item-skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 260px;
  }
  .listing-item-skeleton__image {
    width: listing.$listingImageW;
    height: listing.$listingImageH;
    margin-bottom: 15px;
    border-radius: listing.$listingImageBorderR;
  }
  .listing-item-skeleton__text {
    width: listing.$listingNameMaxW * 2/3;
    height: 1rem;
  }
</style>