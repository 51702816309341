var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-library"},[(_vm.getCustomLibraries.length)?[_vm._t("header"),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"table-container table-container__shadowed"},[(_vm.isLoadingRowData)?_c('ScheduleSkeleton',{attrs:{"header":"","header-columns":5,"body-rows":6}}):_c('v-data-table',{attrs:{"item-key":"id","must-sort":"","items":_vm.getCustomLibraries,"hide-default-footer":"","mobile-breakpoint":0,"elevation":0,"disable-pagination":"","headers":_vm.headersForRendering,"data-test":"library_table"},on:{"click:row":function($event){return _vm.navigateToCollectionList($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"id":item.id,"data-test":"library_name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.collectionCount",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"collection_count_value"}},[_vm._v(_vm._s(item.collectionCount))])]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('Creator',{attrs:{"member":item.creator}})]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('Members',{attrs:{"members":item.members}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',_vm._g({staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-action-menu', {
                item: item,
                event: $event,
              })}}},_vm.$listeners),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)})],1)])]:_c('AppEmptyList',{attrs:{"from":"libraries"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }