<template>
  <v-skeleton-loader
    class="my-5"
    :width="150"
    :height="24"
    type="heading" />
</template>

<script>
export default {
  name: 'CollectionListingHeaderSkeleton',
};
</script>

<style scoped lang="scss">
  .v-skeleton-loader.v-skeleton-loader--is-loading {
    ::v-deep .v-skeleton-loader__heading {
      height: 100%;
      width: 100%;
    }
  }
</style>