<template>
  <div class="listing-image">
    <div
      v-if="!logoClass"
      class="relative">
      <div class="verified-check">
        <AppVerifiedMark
          :is-verified="isVerified"
          :child-verified-ws="childVerifiedWs"
          :verified-page-id="verifiedPageId"
          :verified-page-name="verifiedPageName"
          :ignore-feature-flag="true" />
      </div>
      <div
        v-if="backgroundFilesLength"
        class="images">
        <div
          v-if="renderedLogo"
          class="back-image-item back-image-item--logo-wrapper">
          <img
            v-lazy="renderedLogo.url"
            class="logo-image"
            alt="logo"
            @error="onError(renderedLogo.key)">
        </div>
        <template v-if="showCollage">
          <div
            v-for="(file, index) in collageImages"
            :key="file.key"
            :data-key="file.key"
            :class="imagesClass(index)"
            :style="collageImagesLength=== 3 && index !== 0 ? 'height: 50%' : 'height: 100%'">
            <app-proxy-pdf
              v-if="checkifPdf(file)"
              :src="file.url" />
            <img
              v-else
              v-lazy="file.url"
              alt="default_Image_item"
              @error="onError(file.id)">
          </div>
        </template>
      </div>
      <div
        v-else
        class="images">
        <v-img
          src="@/assets/icons/placeholder.svg"
          alt="logo"
          width="100%"
          height="auto" />
      </div>
    </div>
    <div
      v-else
      :class="logoClass"
      class="images" />
  </div>
</template>
<script>
import { Storage } from 'aws-amplify';
import { parseStorageKey } from '@/utils';
import appProxyPdf from '@/components/App/AppProxyPdf/index.vue';
import AppVerifiedMark from '@/components/App/AppVerifiedMark';
import fileAttempts from '@/mixins/fileAttempts';
import {
  mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'ListingImage',
  components: {
    appProxyPdf,
    AppVerifiedMark,
  },
  mixins: [fileAttempts],
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
    collection: {
      type: Object,
      default: () => null,
    },
    watchForPictures: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      backgroundFiles: [],
      attempts: {
      },
    };
  },
  computed: {
    ...mapGetters('ManageFiles', ['getFileByKey', 'getFullFileByUrl']),
    ...mapGetters('FeatureFlags', ['hideCollectionCollage', 'useIncreaseCaching', 'useNewPdfLibrary']),
    showCollage() {
      return this.createdProUser || !this.renderedLogo;
    },
    createdProUser() {
      return this.collection?.createdProUser;
    },
    isVerified() {
      return this.collection?.isVerified;
    },
    childVerifiedWs() {
      return this.collection?.childVerified;
    },
    verifiedPageId() {
      return this.collection?.verifiedPageId;
    },
    verifiedPageName() {
      return this.collection?.verifiedPageName;
    },
    renderedLogo() {
      return this.backgroundFiles.find(item => item?.isLogo && item.url);
    },
    collageImages() {
      return this.hideCollectionCollage
        ? this.backgroundFiles.filter(item => !item?.isLogo).splice(0, 1)
        : this.backgroundFiles.filter(item => !item?.isLogo)
      ;
    },
    collageImagesLength() {
      return this.collageImages.length;
    },
    backgroundFilesLength() {
      return this.backgroundFiles.length;
    },
    logoClass() {
      const { collection } = this;
      if (!collection) {
        return false;
      }
      const { kind, id } = collection;
      if (id === 'community') {
        return 'community-background';
      } else if (kind === 'default' && !this.collection.logo) {
        return 'my-library-background ';
      } return false;
    },
  },
  watch: {
    pictures: {
      handler(val, oldVal) {
        if (!this.lodash.isEqual(val, oldVal)) {
          this.backgroundFiles = [];
          this.getUrls();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    getUrls(replace = false) {
      this.pictures.map(el => {
        if (el?.isLogo) {
          this.getLogos(el, replace);
        } else if (el?.attachment?.key) {
          this.getImages(el.attachment, replace);
        }
      });
    },
    onError(fileId) {
      const foundPicture = this.pictures.find((picture) => {
        if (picture?.isLogo) {
          return picture.key == fileId;
        }
        return picture?.attachment.id == fileId;
      });
      let fileKey = foundPicture?.isLogo ? foundPicture.key : foundPicture?.attachment.key;
      const { key } = parseStorageKey(fileKey);
      this.addAttempts(key);
      if (!this.haveEnoughAttempts(key)) {
        return;
      }
      this.removeFilesFromCache({
        keys: [key],
      });
      this.backgroundFiles = this.backgroundFiles.map(file => {
        if (file.key === key) {
          return {
            url: '',
            ...file,
          };
        }
        return file;
      });
      this.getUrls(true);
    },
    async getLogos({ key, ...rest }, replace = false) {
      try {
        let url = '';
        if (this.useIncreaseCaching) {
          const parsedKey = parseStorageKey(key);
          await this.parseFile({
            key: parsedKey.key,
            config: {
              level: 'public',
            },
          });
          url = this.getFileByKey(parsedKey.key);
        } else {
          url = await Storage.get(key, {
            level: 'public',
          });
        }
        const newImage = {
          ...rest,
          url,
          key,
        };
        if (!replace) {
          this.backgroundFiles = [
            ...this.backgroundFiles,
            newImage,
          ];
          return;
        }
        this.backgroundFiles = this.backgroundFiles.map(file => {
          return file.key == key ? newImage : file;
        });
      } catch (err) {
        console.log('err', err);
      }
    },
    async getImages({ key, ...rest }, replace = false) {
      const url = await this.getImagesUrl(key, replace);
      const newImage = {
        ...rest,
        url,
      };
      if (!replace) {
        this.backgroundFiles = [
          ...this.backgroundFiles,
          newImage,
        ];
        return;
      }
      this.backgroundFiles = this.backgroundFiles.map(file => {
        return file.id == rest.id ? newImage : file;
      });
    },
    async getImagesUrl(initKey) {
      let url = null;
      if (initKey) {
        const { identityId, key } = parseStorageKey(initKey);
        if (this.useIncreaseCaching) {
          await this.parseFile({
            key,
            config: {
              level: 'protected',
              identityId,
            },
          });
          url = this.getFileByKey(key);
        } else {
          url = await Storage.get(
            key, {
              level: 'protected',
              identityId,
            });
        }
      }
      return url;
    },
    checkifPdf(item) {
      return item?.type?.includes('pdf');
    },
    imagesClass(element) {
      const imageClass = ['back-image-item', 'first-image-item', 'second-image-item'];
      return imageClass[element];
    },
  },
};
</script>
<style lang="scss" scoped>
@use 'src/components/Listing/listing';
.listing-image {
  .verified-check {
    position: absolute;
    z-index: 4;
    right: 0;
    top: 0;
    transform: translate(25%, -25%);
  }

  .images {
    width: listing.$listingImageW;
    height: listing.$listingImageH;
    border-radius: listing.$listingImageBorderR;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 115%;
    background-position: center;
    display: grid;
    position: relative;
    overflow: hidden;
    transition: 0.5s;

    .first-image-item, .second-image-item {
      transition: 0.5s;
      position: absolute;
      left: 65%;
      width: 51%;

      img {
        object-fit: cover;
      }
    }
    .back-image-item, .first-image-item, .second-image-item {
      img {
        width: 100%;
        height: 100%;
      }
      span {
        height: 100%;
        canvas {
          height: 100%!important;
          width: auto!important;
        }
      }
    }
    .back-image-item {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.5s;
      display: grid;
      z-index: 1;

      &--logo-wrapper {
        position: absolute;
        z-index: 3;

        .logo-image {
          object-fit: contain;
        }
      }

      & img {
        max-height: 144px;
        max-width: 218px;
        object-fit: cover;
      }
    }

    .first-image-item {
      top: 0;
      z-index: 2;
    }

    .second-image-item {
      bottom: 0;
      z-index: 2;
    }
  }

  .images:hover {
    transition: 0.5s;
    background-size: 130%;

    .back-image-item:hover {
      transition: 0.5s;
      transform: scale(1.2);
    }

    .first-image-item, .second-image-item {
      transition: 0.5s;
      left: 60%;
    }

    .first-image-item:hover, .second-image-item:hover {
      transition: 0.5s;
      left: 55%;
    }
  }
}
</style>
