<script>
export default {
  name: 'AppTextHeader',
  functional: true,
  props: {
    number: {
      type: Number,
      default: 1,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  render: function (h, { scopedSlots, props }) {
    return h('h'.concat(props.number), {
      class: props.classes,
    }, scopedSlots.default());
  },
};
</script>
<style scoped>
  h3 {
    font-size: 24px;
  }
</style>
