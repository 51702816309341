<template>
  <v-tooltip
    top
    :color="colorTooltip"
    content-class="btn-tooltip">
    <template v-slot:activator="{ on }">
      <button
        :disabled="isDisabled"
        :class="getContainerClass"
        v-on="isDisabled ? on : ''"
        @click="clickedEvent">
        <div :class="getBtnClass">
          <v-icon
            :color="type == 'footer' ? 'white' : ''"
            :size="type == 'footer' ? 46 : 30">
            mdi-plus
          </v-icon>
        </div>
      </button>
    </template>
    <span>{{ reasonText }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import appBtnMixin from '@/mixins/appBtnMixin';
export default {
  name: 'AppAddButtonNoMenu',
  mixins: [appBtnMixin],
  props: {
    type: {
      type: String,
      default: 'footer',
      validator(value) {
        return ['footer', 'basic'].includes(value);
      },
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
    getContainerClass() {
      let res;
      if (this.type == 'footer') {
        if (this.showAIChat) {
          res = 'add-icon-container z-index-min secondary-space';
        } else {
          res = 'add-icon-container z-index-min';
        }
      } else {
        res = 'add-icon-container__relative';
      }
      return this.disabled ? res + ' disabled' : res;
    },
    getBtnClass() {
      if (this.type == 'footer') {
        return 'add-icon-wrapper__right';
      }
      return 'add-icon-wrapper';
    },
  },
  methods: {
    clickedEvent() {
      if (!this.disabled) this.clickCallback();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.add-icon-container {
  position: fixed;
  right: 44px;
  bottom: 34px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    bottom: 88px;
    right: 6px;
  }

  &.secondary-space {
    right: 104px;
    bottom: 34px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      bottom: 88px;
      right: 66px;
    }
  }
}
.add-icon-container:hover {
  .add-icon-wrapper {
    background: linear-gradient(270deg, #71D6E0 -42.03%, #2361BE 134.48%);
  }
}
.add-icon-wrapper {
  border-radius: 50px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  &__right {
    float: right;
    background: linear-gradient(270deg, #71D6E0 -42.03%, #0AA8DA 134.48%);
    border-radius: 50px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
}
::v-deep .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: rgba(0, 0, 0, 0) !important;
}
.z-index-min {
  z-index: 99;
}
.disabled {
  opacity: 0.3;
}
</style>
