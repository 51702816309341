<template>
  <div>
    <div class="px-9 d-flex justify-space-between">
      <app-breadcrumbs
        :push-back-url="breadcrumbItems.pushBackUrl"
        :breadcrumbs-list="breadcrumbItems.breadcrumbs" />
      <app-switcher-to-listing />
    </div>
    <v-col
      class="mb-12"
      :class="isListingView ? 'library-page-container' : 'page-container'">
      <component
        :is="isListingView ? 'ListingLibraries' : 'LibrariesTable'"
        @click-action-menu="setMenuItem($event)">
        <template v-slot:header>
          <app-text-header
            :number="3"
            classes="pl-6 mb-4 font-weight-regular">
            My Libraries
          </app-text-header>
        </template>
      </component>
      <div
        class="mt-2">
        <LibraryCreateOrUpdate
          :class="isListingView ? 'library-page-container__action' : ''"
          @saveNewItem="saveNewLibrary"
          @updateCollectionOrLibrary="updateLibrary" />
      </div>
    </v-col>
    <Actions
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard" />
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
// components
import Actions from '@/components/Libraries/LibrariesActions';
import AppBreadcrumbs from '@/components/App/AppBreadcrumbs';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import AppTextHeader from '@/components/App/AppTextHeader';
import LibrariesTable from '@/components/Libraries/LibrariesTable';
import LibraryCreateOrUpdate from '@/components/CollectionsLibrarysRelocate';
import ListingLibraries from '@/components/Listing/Libraries';
// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
// constants + utils
import { TYPE_LIBRARY_CUSTOM } from '@/constants';
import { getFullLinkForWorkspaces } from '@/utils';
import LibrariesApi from '@/services/graphql/libraries';
export default {
  name: 'Libraries',
  components: {
    Actions,
    AppBreadcrumbs,
    AppSwitcherToListing,
    AppTextHeader,
    LibrariesTable,
    ListingLibraries,
    LibraryCreateOrUpdate,
  },
  mixins: [ToggleViewCondition, AppActionDotsMenu],
  data: () => ({
    TYPE_LIBRARY_CUSTOM,
  }),
  computed: {
    ...mapState(['updateLibraryOrCollection']),
    ...mapGetters('Libraries', ['getCustomLibraries']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    breadcrumbItems() {
      const pushBackUrl = getFullLinkForWorkspaces('/');
      return {
        breadcrumbs: [
          {
            text: 'Home',
            disabled: false,
            to: pushBackUrl,
          },
          {
            text: 'Libraries',
            disabled: true,
          },
        ],
        pushBackUrl,
      };
    },
  },
  methods: {
    ...mapActions({
      setNewLibrary: 'Libraries/setNewLibrary',
      handleError: 'handleError',
      updateLibraryName: 'Libraries/updateLibraryName',
    }),
    ...mapMutations(['spinner']),
    async saveNewLibrary(items) {
      this.spinner(true);
      const { name } = items;
      try {
        const workspaceId = this.activeWorkspaceId;
        const create = 'createV2';
        const { data } = await LibrariesApi[create]({
          name,
          workspaceId,
        });
        this.setNewLibrary(data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async updateLibrary(items) {
      this.spinner(true);
      const { name, description, logo } = items;
      const { id } = this.updateLibraryOrCollection;
      try {
        const workspaceId = this.activeWorkspaceId;
        const data = await LibrariesApi.update({
          libraryId: id,
          name,
          description,
          logo,
          workspaceId,
        });
        this.updateLibraryName(data.data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style lang="scss">
.library-page-container {
  &__action {
    max-width: 1248px !important;
    margin: 0px auto;
    display: flex;
    justify-content: center;
  }
}
</style>
