<template>
  <div
    v-if="members"
    class="members"
    @click.stop="$emit('showShareModalAction')">
    <template v-if="totalMembers">
      <span
        v-for="(member,index) in filteredMembers"
        :key="member.id"
        :style="{ position: 'absolute',left:`calc(${20 * index}px)`, 'z-index': totalFilteredMembers - index}"
        class="member">
        <Member
          :color-icon="member.color || ''"
          :hide-avatar="hideAvatar"
          :disabled-tooltip="disabledTooltip"
          :member="member" />
      </span>
    </template>
    <div
      v-if="(totalMembers > visibleMembers ) || !totalMembers"
      :style="{left: `${(20 * totalFilteredMembers) + 3}px`}"
      class="member-lenght">
      <span v-show="!overflowDots">{{ memberLength }}</span>
      <span v-show="overflowDots">...</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Member from '../AppTableMember';
export default {
  name: 'AppTableMembers',
  components: {
    Member,
  },
  props: {
    isFilterLastMembers: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    visibleMembers: {
      type: Number,
      default: 4,
    },
    members: {
      type: [Array, String],
      default: () => [],
    },
    memberCount: {
      type: Number,
      default: -1,
    },
    overflowDots: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['avatars']),
    totalMembers() {
      return this.members.length;
    },
    memberLength() {
      // Performance tanks when fetching too many members, so we fetch a few + count (set as memberCount prop).
      const { members, totalMembers, visibleMembers } = this;
      const totalCount = this.memberCount > 0
        ? this.memberCount - visibleMembers
        : !members.length ? 0 : totalMembers - visibleMembers;
      return totalCount > 99 ? '+99' : totalCount;
    },
    totalFilteredMembers() {
      return this.filteredMembers.length;
    },
    filteredMembers() {
      const newMembers = this.members?.filter(member => member) || [];
      return this.isFilterLastMembers ? newMembers.slice( -this.visibleMembers).reverse() : newMembers.slice( 0, this.visibleMembers);
    },
  },
  methods: {
  },
};
</script>
