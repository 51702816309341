<template>
  <div
    v-if="(useLazyLoading && isFetching) || librariesList.length"
    class="listing-image-block">
    <slot name="createCollectionButton" />
    <div
      v-for="(item, i) in librariesList"
      :id="item.id"
      :key="item.id"
      v-intersect.once="onIntersect"
      :style="showCollapsedItems && i > collapsedItemsAmount - 1 && 'display:none;'"
      class="items main-items"
      @click="navigate(item)">
      <!-- link to collection -->
      <component
        :is="createAnchorForCollections ? 'router-link' : 'div'"
        :to="item.route || ''"
        :href="item.route || ''"
        target="_blank"
        @click.native.prevent>
        <!-- badge to show unread comments -->
        <v-badge
          v-show="showUnreadComments && item.commentsUnread"
          class="collections__unread-comments-badge"
          :content="item.commentsUnread" />
        <div
          v-if="item.publishExpirationDate"
          class="expired-collection-icon">
          <v-badge
            content="!" />
        </div>
        <!-- images -->
        <ListingImage
          :collection="item"
          :pictures="setPictures(item)" />
      </component>
      <div class="item-text">
        <span
          class="d-inline-flex align-center">
          <!-- title -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="listing-name"
                v-bind="attrs"
                v-on="on">
                {{ item.name }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
          <!-- options -->
          <slot
            name="actions"
            :item="item" />
        </span>
        <!-- product count -->
        <span
          v-if="showCounter"
          class="libraryCounter">
          {{ renderAmount(item) }}
        </span>
      </div>
    </div>
    <template v-if="useLazyLoading && isFetching">
      <div class="items main-items">
        <ListingItemSkeleton />
      </div>
      <div class="items main-items">
        <ListingItemSkeleton />
      </div>
      <div class="items main-items d-none d-sm-block">
        <ListingItemSkeleton />
      </div>
      <div class="items main-items d-none d-md-block">
        <ListingItemSkeleton />
      </div>
    </template>
  </div>
</template>
<script>
import ListingImage from './ListingImage';
import ListingItemSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingItemSkeleton';
import { mapGetters } from 'vuex';
import SetPictures from '@/mixins/SetPictures';
export default {
  name: 'ListingImageItem',
  components: {
    ListingImage,
    ListingItemSkeleton,
  },
  mixins: [SetPictures],
  props: {
    collapsedItemsAmount: {
      type: Number,
      default: 4,
    },
    counter: {
      type: Object,
      default: () => ({
        prop: 'collectionCount',
        type: 'collections',
      }),
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    librariesList: {
      type: Array,
      default: () => [],
    },
    showCollapsedItems: {
      type: Boolean,
      default: false,
    },
    showUnreadComments: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: () => null,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('FeatureFlags', [
      'createAnchorForCollections',
      'useLazyLoading']),
  },
  methods: {
    navigate(item) {
      if (this.createAnchorForCollections && item.route) {
        this.$router.push(item.route);
      } else {
        this.$emit('navigate', item);
      }
    },
    onIntersect(entries, observer, isIntersecting) {
      if (!this.intersect) {
        return;
      }
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
    renderAmount(item) {
      let count = item[this.counter.prop];
      if (!count || count < 0) {
        count = 0;
      }
      return `${count} ${this.counter.type}`;
    },
  },
};
</script>
<style scoped lang="scss">
@use 'src/components/Listing/listing';
.expired-collection-icon {
  position: absolute;
  right: 9px;
  top: 0px;
}
.listing-name {
  max-width: listing.$listingNameMaxW;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  cursor: pointer;
}
.collections__unread-comments-badge {
  ::v-deep .v-badge__badge {
    left: -110px !important;
  }
}
</style>
