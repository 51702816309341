<template>
  <app-kebab-actions
    v-if="libraryId !== 'community'"
    :value="value"
    v-bind="$attrs"
    @showMenu="onShowMenu"
    v-on="$listeners">
    <template #activator="{ on }">
      <div
        class="pointer"
        v-on="on">
        <v-icon
          medium
          color="mainGrey"
          class="brightness-icon">
          mdi-dots-vertical
        </v-icon>
      </div>
    </template>
    <p
      @click="showInfo()">
      <v-icon
        size="15"
        color="lightBlack">
        mdi-information-outline
      </v-icon>
      Info
    </p>
    <LibraryShareDialog
      :invite-to-text-type="LIBRARY"
      :item="item || {
      }"
      :members="itemMembers"
      text="Share"
      view-in-actions
      @manageSharedUser="manageSharedUser({
        variables: {
          ...$event.variables,
          libraryId: libraryId,
        },
        criteria: $event.criteria,
      })" />
    <library-delete
      :item="item || {
      }">
      Delete
    </library-delete>
  </app-kebab-actions>
</template>
<script>
import {
  mapActions,
  mapMutations,
} from 'vuex';
import LibraryShareDialog from '@/components/CollectionsLibrarysRelocate/ShareDialog';
import { LIBRARY } from '@/constants/cores';
import LibraryDelete
  from '@/components/Libraries/LibrariesActions/LibraryActionsComponent/LibraryDelete';
import AppKebabActions from '@/components/App/AppKebabActions';
export default {
  name: 'LibrariesActions',
  components: {
    LibraryDelete,
    LibraryShareDialog,
    AppKebabActions,
  },
  props: {
    librariesList: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      LIBRARY,
    };
  },
  computed: {
    libraryId() {
      const { id } = this.item ?? {
      };
      return id;
    },
    itemMembers() {
      const { members } = this.item ?? {
      };
      if (!members) return [];
      return members;
    },
  },
  methods: {
    ...mapMutations(['spinner', 'openInfoModal', 'setIsLoadingRowData']),
    ...mapActions({
      getInfo: 'getInfo',
      manageSharedUser: 'Libraries/manageSharedUser',
    }),
    async onShowMenu(val) {
      if (!val) {
        this.$emit('update:item', null);
      }
    },
    async showInfo() {
      this.spinner(true);
      const { libraryId } = this;
      await this.getInfo({
        libraryId,
        windowStatus: 'update',
      });
      this.openInfoModal(true);
      this.spinner(false);
    },
  },
};
</script>
<style scoped lang="scss">
</style>
