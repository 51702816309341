<template>
  <div class="table-library">
    <template v-if="getCustomLibraries.length">
      <slot name="header" />
      <div class="table-block">
        <div class="table-container table-container__shadowed">
          <ScheduleSkeleton
            v-if="isLoadingRowData"
            header
            :header-columns="5"
            :body-rows="6" />

          <v-data-table
            v-else
            item-key="id"
            must-sort
            :items="getCustomLibraries"
            hide-default-footer
            :mobile-breakpoint="0"
            :elevation="0"
            disable-pagination
            :headers="headersForRendering"
            data-test="library_table"
            @click:row="navigateToCollectionList($event)">
            <template #[`item.name`]="{ item }">
              <div
                :id="item.id"
                data-test="library_name"
                style="display: flex; align-items: center">
                {{ item.name }}
              </div>
            </template>
            <template #[`item.collectionCount`]="{ item }">
              <span data-test="collection_count_value">{{ item.collectionCount }}</span>
            </template>
            <template #[`item.creator`]="{ item }">
              <Creator :member="item.creator" />
            </template>
            <template #[`item.members`]="{ item }">
              <Members :members="item.members" />
            </template>
            <template #[`item.action`]="{ item }">
              <v-icon
                medium
                color="mainGrey"
                class="brightness-icon"
                @click.stop="$emit('click-action-menu', {
                  item,
                  event: $event,
                })"
                v-on="$listeners">
                mdi-dots-vertical
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </template>

    <AppEmptyList
      v-else
      from="libraries" />
  </div>
</template>
<script>
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import TableHeadersToMobile from '@/mixins/TableHeadersToMobile';
// components
import Creator from '@/components/App/AppTable/AppTableMember';
import Members from '@/components/App/AppTable/AppTableMembers';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
export default {
  name: 'LibrariesTable',
  components: {
    Creator,
    Members,
    ScheduleSkeleton,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  mixins: [TableHeadersToMobile],
  data: function () {
    return {
      // for TableHeadersToMobile mixin
      MOBILE_COLS_TO_RENDERING: [
        'name',
        'action',
      ],
      headers: [
        {
          text: 'Library name', value: 'name',
          sortable: false,
          cellClass: 'library__name--cell',
        },
        {
          text: 'Collection Count', value: 'collectionCount', align: 'center',
          sortable: false,
        },
        {
          text: 'Creator', value: 'creator',
          sortable: false,
        },
        {
          text: 'Members', align: 'left', sortable: false, value: 'members',
        },
        {
          text: '', value: 'action', sortable: false, align: 'right',
        },
      ],
      permissionTypes: [
        {
          value: 'viewer', text: 'Viewer',
        },
        {
          value: 'editor', text: 'Editor',
        },
        {
          value: 'creator', text: 'Creator',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Libraries', ['getCustomLibraries']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState(['isLoadingRowData']),
  },
  async created() {
    await this.getLibrariesList();
  },
  methods: {
    ...mapActions({
      getLibrariesList: 'Libraries/getLibrariesList',
    }),
    navigateToCollectionList({ id }) {
      this.$router.push({
        path: id === 'community' ? `community-collections/${id}` : `collections/${id}`,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.table-library {
  display: grid;
  gap: 36px;
  margin-top: 50px;

  .table-container {
    margin: 0 auto;
  }
  ::v-deep .library__name--cell {
    font-weight: bold;
  }
  .table-block {
    .table-container__shadowed {
      max-height: 535px;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
