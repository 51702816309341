<template>
  <div class="mt-12">
    <CollectionListingHeaderSkeleton class="mb-10" />
    <div class="d-flex flex-wrap justify-md-start">
      <CollectionListingItemSkeleton
        v-for="n in 4"
        :key="n"
        class="pa-3 flex-grow-1 mx-md-0 mx-auto" />
    </div>
  </div>
</template>

<script>
import CollectionListingHeaderSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingHeaderSkeleton';
import CollectionListingItemSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingItemSkeleton';
export default {
  name: 'LibraryListingSectionSkeleton',
  components: {
    CollectionListingHeaderSkeleton,
    CollectionListingItemSkeleton,
  },
};
</script>
